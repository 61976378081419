import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './component/App';
import Donate from './component/Donate'
import WishList from './component/WishList'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga';

import i18n from "i18next";
import { withI18n, reactI18nextModule } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationUK from './locales/uk/translation.json';

ReactGA.initialize('UA-54618239-7');
ReactGA.pageview(window.location.pathname + window.location.search);

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    },
    uk: {
        translation: translationUK
    }
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });

const AppWithI18n = withI18n()(App); // pass `t` function to App
const DonateWithI18n = withI18n()(Donate);
const WishListWithI18n = withI18n()(WishList);
ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/" component={AppWithI18n} />
            <Route exact path="/donate" component={DonateWithI18n} />
            <Route exact path="/wish-list" component={WishListWithI18n} />
            <Route exact path="/:lang/" component={AppWithI18n} />
            <Route exact path="/:lang/donate" component={DonateWithI18n} />
            <Route exact path="/:lang/wish-list" component={WishListWithI18n} />
        </Switch>
    </Router>,
    document.getElementById('root')
);

//TODO check later
serviceWorker.unregister();
