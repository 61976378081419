import visaMaster from "../img/visa_mastercard.png";
import React, {Component} from "react";

class Footer extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div className="navbar navbar-fixed-bottom">
                <div className="header-right hidden-sm hidden-xs">
                    <img className="visa-master" src={visaMaster}/>© Created By Cats For People 2015-{new Date().getUTCFullYear()}
                </div>
                <div className="header-right hidden-lg hidden-md">
                    © Created By Cats For People 2015-{new Date().getUTCFullYear()}<br/><img className="visa-master" src={visaMaster} alt={""}/>
                </div>
            </div>
        )
    }
}

export default Footer;