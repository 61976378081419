import React, {Component} from "react";
import i18n from "i18next";
import whiteLogo from "../img/logo_white.svg";
import donate_cat from "../img/catcafe-donate-2.jpg";
import Footer from "./Footer";
import Header from "./Header";

class Donate extends Component {
    constructor(props){
        super(props);
        const lang = this.props.match.params.lang ? this.props.match.params.lang : 'en';
        i18n.changeLanguage(lang);
    }
    render() {
        const {t} = this.props;
        const lang = this.props.match.params.lang ? this.props.match.params.lang : 'en';
        return (
            <div className="App">
                <Header props={this.props}/>
                <div className="row hidden-sm hidden-xs">
                    <div className="col-sm-6">
                        <div className="about-us-text align-by-vertical">
                            <div className="logo">
                                <a href={"/"+lang+"/"}>
                                    <img src={whiteLogo} alt={"white logo"}/>
                                </a>
                            </div>
                            <div className="about-us-image-mobile hidden-lg hidden-md">
                                <img src={donate_cat} alt="CatCafe Donate"/>
                            </div>
                            <div className="description">
                                {t('donate->description')}
                            </div>
                            <div className="donate-items">
                                {t("donate->select->item->title")}<br/>
                                <button style={{fontSize:"14px", minWidth: "210px", margin: "5px auto", display: "block"}} className={"donate-btn"}>
                                    <a href="https://secure.wayforpay.com/button/b281c420422e9">{t("donate->item->#4")}</a>
                                </button>
                            </div>
                            <div className="donate-items-long-text">
                                <div className="donate-requisites"><b>PayPal</b>:<br/>
                                    info@catcafe.ck.ua
                                </div>
                                <div className="donate-requisites"><b>SWIFT (USD)</b>:<br/>
                                    * BENEFICIARY: TKACHENKO ANNA, Zip Code 18003, Ukraine, region Cherkaska,city Cherkasy, street Chaikovskoho, building 63,flat 15<br/>
                                    * IBAN: UA663052990262016400929263517<br/>
                                    * ACCOUNT: 4731219631292640<br/>
                                    * BANK OF BENEFICIARY: PRIVATBANK, 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE<br/>
                                    * SWIFT CODE/BIC: PBANUA2X<br/>
                                    * INTERMEDIARY BANK: JP MORGAN CHASE BANK<br/>
                                    * SWIFT CODE/BIC: CHASUS33<br/>
                                    * CORRESPONDENT ACCOUNT: 0011000080 <br/>
                                </div>
                                <div className="donate-requisites"><b>SWIFT (EUR)</b>:<br/>
                                    * BENEFICIARY: TKACHENKO ANNA, Zip Code 18003,Ukraine, region Cherkaska, city Cherkasy, street Chaikovskoho,building 63,flat 15<br/>
                                    * IBAN: UA773052990262036400930628176<br/>
                                    * ACCOUNT: 4149499372863116 <br/>
                                    * BANK OF BENEFICIARY: PRIVATBANK, 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE<br/>
                                    * SWIFT CODE/BIC: PBANUA2X<br/>
                                    * INTERMEDIARY BANK: Commerzbank AG Frankfurt am Main Germany<br/>
                                    * SWIFT CODE/BIC: COBADEFF<br/>
                                    * CORRESPONDENT ACCOUNT: 400886700401<br/>
                                </div>
                            </div>
                            <div className="donate-notes">{t("donate->notes")}</div>
                            <div className="contacts">+38 (073) 48-59-198<br/>info&#64;catcafe.ck.ua</div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="about-us-image align-by-vertical">
                            <img src={donate_cat} alt="CatCafe Donate"/>
                        </div>
                    </div>
                </div>
                <div className="row hidden-lg hidden-md">
                    <div className="col-sm-12">
                        <div className="about-us-text">
                            <div className="logo">
                                <img src={whiteLogo} alt={"white logo"}/>
                            </div>
                            <div className="about-us-image-mobile">
                                <img src={donate_cat} alt="CatCafe Donate"/>
                            </div>
                            <div className="description">
                                {t('donate->description')}
                            </div>
                            <div className="donate-items">
                                {t("donate->select->item->title")}<br/>
                                <button style={{fontSize:"14px", minWidth: "210px", margin: "5px"}} className={"donate-btn"}>
                                    <a href="https://secure.wayforpay.com/button/b281c420422e9">{t("donate->item->#4")}</a>
                                </button>
                            </div>
                            <div className="donate-items-long-text">
                                <div className="donate-requisites"><b>PayPal</b>:<br/>
                                    info@catcafe.ck.ua
                                </div>
                                <div className="donate-requisites"><b>SWIFT (USD)</b>:<br/>
                                    * BENEFICIARY: TKACHENKO ANNA, Zip Code 18003, Ukraine, region Cherkaska,city Cherkasy, street Chaikovskoho, building 63,flat 15<br/>
                                    * IBAN: UA663052990262016400929263517<br/>
                                    * ACCOUNT: 4731219631292640<br/>
                                    * BANK OF BENEFICIARY: PRIVATBANK, 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE<br/>
                                    * SWIFT CODE/BIC: PBANUA2X<br/>
                                    * INTERMEDIARY BANK: JP MORGAN CHASE BANK<br/>
                                    * SWIFT CODE/BIC: CHASUS33<br/>
                                    * CORRESPONDENT ACCOUNT: 0011000080 <br/>
                                </div>
                                <div className="donate-requisites"><b>SWIFT (EUR)</b>:<br/>
                                    * BENEFICIARY: TKACHENKO ANNA, Zip Code 18003,Ukraine, region Cherkaska, city Cherkasy, street Chaikovskoho,building 63,flat 15<br/>
                                    * IBAN: UA773052990262036400930628176<br/>
                                    * ACCOUNT: 4149499372863116 <br/>
                                    * BANK OF BENEFICIARY: PRIVATBANK, 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE<br/>
                                    * SWIFT CODE/BIC: PBANUA2X<br/>
                                    * INTERMEDIARY BANK: Commerzbank AG Frankfurt am Main Germany<br/>
                                    * SWIFT CODE/BIC: COBADEFF<br/>
                                    * CORRESPONDENT ACCOUNT: 400886700401<br/>
                                </div>
                            </div>
                            <div className="donate-notes">{t("donate->notes")}</div>
                            <div className="contacts">+38 (073) 48-59-198<br/>info&#64;catcafe.ck.ua</div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Donate;