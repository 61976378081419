import React, {Component} from "react";
import i18n from "i18next";
import Footer from "./Footer";
import Header from "./Header";
import WishListSlide from "./WishListSlide";
import Slider from "react-slick";

class WishList extends Component {
    constructor(props){
        super(props);
        const lang = this.props.match.params.lang ? this.props.match.params.lang : 'en';
        i18n.changeLanguage(lang);
    }
    render() {
        const settingsDesktop = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false
        };
        const settingsMobile = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false
        };
        return (
            <div className="App">
                <Header props={this.props}/>
                <div className="row hidden-sm hidden-xs">
                    <div className="col-sm-12" style={{padding: "0 60px 0 60px"}}>
                        <div>
                            <Slider {...settingsDesktop} style={{height: "70vh", top: "6vh"}}>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_running_wheel"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_ferplast"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_trixie"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_tower"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_buggin"/>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="row hidden-lg hidden-md">
                    <div className="col-sm-12" style={{padding: "0 60px 0 60px"}}>
                        <div>
                            <Slider {...settingsMobile}>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_running_wheel"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_ferplast"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_trixie"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_tower"/>
                                </div>
                                <div>
                                    <WishListSlide url="https://secure.wayforpay.com/payment/catcafe_buggin"/>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default WishList;