import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import backgroundCat from "../img/background_cat.svg";

class Header extends Component {
    constructor(props){
        super(props);
    }
    render() {
        const {t} = this.props.props;
        const lang = this.props.props.match.params.lang ? this.props.props.match.params.lang : 'en';
        var location = this.props.props.match.url.replace("/"+lang+"/", "");
        if (location.startsWith("/")) {
            location = location.substr(1, location.length);
        }
        return (
            <div>
                <MetaTags>
                    <title>{t('home->title')}</title>
                    <meta name="description" content={t('about-us->description')} />
                    <meta property="og:title" content={t('home->title')} />
                </MetaTags>
                <div className="top-bar">
                    <div className={"header-left"}>

                    </div>
                    <div className={"header-left lang-menu"}>
                        <a className={lang === 'uk' ? 'line-hover-active' : 'line-hover'} href={"/uk/"+location}>УКР</a>
                        {/*<a className={lang === 'ru' ? 'line-hover-active' : 'line-hover'} href={"/ru/"+location}>РУС</a>*/}
                        <a className={lang === 'en' ? 'line-hover-active' : 'line-hover'} href={"/en/"+location}>ENG</a>
                    </div>
                </div>
                <div className="home dark">
                    <div className="logo">
                        <img src={backgroundCat} style={{header: "80%", width: "100%"}} alt="logo"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;