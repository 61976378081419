import React, {Component} from "react";
import Async from 'react-async';

class WishListSlide extends Component {
    constructor(props){
        super(props);
    }
    render() {
        const url = this.props.url;
        const loadWish = () =>
            fetch('https://api.allorigins.win/get?url='+url+'&cacheMaxAge=60')
                .then(res => (res.ok ? res : Promise.reject(res)))
                .then(res => (res.json()));
        return (
            <div className={"wish-list-slide"}>
                <Async promiseFn={loadWish}>
                    {({ data, err, isLoading }) => {
                        if (isLoading) return "Loading...";
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(data.contents, "text/html");
                        const wish = {
                            title: doc.querySelector(".title").textContent,
                            description: doc.querySelector(".description").innerHTML,
                            targetSum: doc.querySelectorAll(".caption")[0].innerText,
                            // currentSum: doc.querySelectorAll(".caption")[1].innerText,
                            currentSum: [...doc.querySelectorAll('.collect-bar .amount span')].map(it => it.textContent).join(" "),
                            progress: doc.querySelector(".progress-bar").ariaValueNow,
                            img: doc.querySelector(".logo").style.backgroundImage.split("\"")[1]
                        };
                        return(
                            <a href={url}>
                            <div className={"row"}>
                                <div className={"col-sm-12"}>
                                    <img src={wish.img}/>
                                    <div className={"title"}>{wish.title}</div>
                                    <div className="description" dangerouslySetInnerHTML={{__html: wish.description}}/>
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar"
                                             style={{width: wish.progress+"%", ariaValuenow:wish.progress, ariaValuemin:"0", ariaValuemax:"100"}}>
                                        </div>
                                    </div>
                                    <div className={"statusUAH"}>
                                        <div className={"currentSum"}>{wish.currentSum}</div>
                                        <div className={"targetSum"}>{wish.targetSum}</div>
                                        <div className={"clearfix"}/>
                                    </div>
                                    <button style={{fontSize:"14px", minWidth: "210px", margin: "5px auto", display: "block"}} className={"donate-btn"}>
                                        <a href={url}>Долучитись</a>
                                    </button>
                                </div>
                            </div>
                        </a>
                        )
                    }}
                </Async>
            </div>
        )
    }
}

export default WishListSlide;